<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <h2 class="fs-2 fw-bold my-2">
            {{ $t('pages.system.sms.multiSend.save.new') }}
        </h2>
        <router-link to="/system/sms/multi-send" class="btn btn-primary align-self-center">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg"/>
            </span>
            {{ $t("pages.system.sms.multiSend.title") }}
        </router-link>
    </div>
    <el-form :model="form.phoneCheck.data" ref="phoneCheckForm">
        <div class="card mb-7">
            <div class="card-body d-flex flex-column p-9">
                <template v-if="!form.phoneCheck.checked">
                    <div class="fv-row mb-1">
                        <label class="required fs-6 fw-bold mb-2">{{ $t('pages.system.sms.multiSend.save.cols.phone') }}</label>
                        <el-form-item prop="items" :rules="$validation.getMessage(['required'])">
                            <el-input v-model="form.phoneCheck.data.items" type="textarea" rows="10"/>
                        </el-form-item>
                    </div>
                    <div class="fv-row mt-2">
                        <el-form-item class="mb-0">
                            <button @click.prevent="onPhoneCheck" :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="button">
                                <span v-if="!form.loading" class="indicator-label">{{ $t("btn.check") }}</span>
                                    <span v-if="form.loading" class="indicator-progress">
                                    {{ $t("messages.wait") }}
                                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </el-form-item>
                    </div>
                </template>
                <div class="fv-row mt-5 text-center" v-if="Object.keys(form.phoneCheck.result).length && form.phoneCheck.checked">
                    <label class="fs-4 fw-bold mb-2">{{ $t('pages.system.sms.multiSend.save.phoneCheck.title') }}</label>
                    <div class="row justify-content-center px-9 mt-5">
                        <div class="col-6 col-md-4 text-center mb-2 mb-xs-0">
                            <div class="text-gray-800 fw-bold fs-3">
                                <span>{{ form.phoneCheck.result.count.total }}</span>
                            </div>

                            <span class="text-gray-700 fs-7 d-block fw-bold">{{ $t('pages.system.sms.multiSend.save.phoneCheck.count.total') }}</span>
                        </div>

                        <div class="col-6 col-md-4 text-center mb-2 mb-xs-0">
                            <div class="text-gray-800 fw-bold fs-3">
                                <span>{{ form.phoneCheck.result.count.correct }}</span>
                            </div>

                            <span class="text-gray-700 fs-7 d-block fw-bold">{{ $t('pages.system.sms.multiSend.save.phoneCheck.count.correct') }}</span>
                        </div>

                        <div class="col-6 col-md-4 text-center mb-2 mb-xs-0">
                            <div class="text-gray-800 fw-bold fs-3">
                                <span>{{ form.phoneCheck.result.count.wrong }}</span>
                            </div>

                            <span class="text-gray-700 fs-7 d-block fw-bold">{{ $t('pages.system.sms.multiSend.save.phoneCheck.count.wrong') }}</span>
                        </div>
                    </div>
                    <div class="mt-12">
                        <button class="btn btn-lg btn-primary" type="button" v-on:click="form.phoneCheck.checked = false">{{ $t("pages.system.sms.multiSend.save.phoneCheck.retryCheck") }}</button>
                    </div>
                </div>
            </div>
        </div>
    </el-form>
    <el-form :model="form.data" ref="multiSendForm" v-if="form.data.items.length > 0">
        <div class="row g-6 mb-6 g-xl-9 mb-xl-9">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-body d-flex flex-column p-9">
                        <div class="fv-row mb-1">
                            <label class="required fs-6 fw-bold mb-2">{{ $t('pages.system.sms.multiSend.cols.message') }}</label>
                            <el-form-item prop="message" :rules="$validation.getMessage(['required'])">
                                <el-input v-model="form.data.message" type="textarea" rows="6" maxlength="512"/>
                            </el-form-item>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="card">
                    <div class="card-body d-flex flex-column p-9">
                        <div class="fv-row mb-1">
                            <label class="fs-6 fw-bold mb-2">{{ $t('pages.system.sms.multiSend.cols.sendDate') }}</label>
                            <el-form-item prop="send_date">
                                <el-date-picker
                                    class="rangeDateTimePicker"
                                    v-model="form.data.send_date"
                                    type="datetime"
                                    popper-class="rangeDateTimePickerPopper"
                                    :placeholder="$t('common.chooseDateAndTime')"
                                    valueFormat="YYYY-MM-DD HH:mm:ss"
                                    :disabledDate="disabledSendDate"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </div>
                        <div class="fv-row mt-5">
                            <el-form-item class="mb-0">
                                <button @click.prevent="onSubmit" :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary w-100" type="button">
                                    <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                                    <span v-if="form.loading" class="indicator-progress">
                                        {{ $t("messages.wait") }}
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </el-form-item>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </el-form>
</template>

<script>
export default {
    name: "index",
    data() {
        return {
            form: {
                loading: false,
                phoneCheck: {
                    checked: false,
                    result: {},
                    data: {}
                },
                data: {
                    items: []
                }
            }
        }
    },
    methods: {
        onPhoneCheck() {
            this.$refs.phoneCheckForm.validate((valid) => {
                if (valid) {
                    let formData = this.preparePhoneCheckFormData();
                    this.form.loading = true;

                    this.axios.post(this.endpoints['sms_check_phone'], formData).then(response => {
                        this.onResponse(response.data, () => {
                            let data = response.data.data;

                            if(data.items && data.items.wrong && data.items.wrong.length) {
                                let blob = new Blob([data.items.wrong.join('\n')], {
                                    type: "text/plain;charset=utf-8;",
                                });

                                this.trustedWindowOpen(URL.createObjectURL(blob), undefined, 'wrong_phones')
                            }

                            if(data.items && data.items.correct && data.items.correct.length){
                                this.form.data.items = data.items.correct;
                            }

                            this.form.phoneCheck.result = data;
                            this.form.phoneCheck.checked = true;
                        }, undefined, false);
                    }).catch(error => {
                        this.onResponseFailure(error.response.data);
                    }).finally(() => {
                        this.form.loading = false;
                    });
                } else {
                    return false;
                }
            });
        },
        preparePhoneCheckFormData(){
            let formData = this.cloneData(this.form.phoneCheck.data);
            formData.items = formData.items.split('\n').filter(item => item.trim().length);

            return formData;
        },
        onSubmit() {
            this.$refs.multiSendForm.validate((valid) => {
                if (valid) {
                    this.form.loading = true;

                    this.axios.post(this.endpoints['sms_multi_send'], this.form.data).then(response => {
                        this.onResponse(response.data, () => {
                            this.$router.push({
                                path: "/system/sms/multi-send"
                            });
                        });
                    }).catch(error => {
                        this.onResponseFailure(error.response.data);
                    }).finally( () => {
                        this.form.loading = false;
                    });
                } else {
                    return false;
                }
            });
        },
        disabledSendDate(sendDate) {
            sendDate = this.$moment(sendDate);

            return this.$moment().add(-1, 'days').valueOf() > sendDate.valueOf()
        },
    }
}
</script>

<style>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}

.rangeDateTimePickerPopper .el-button--text.el-picker-panel__link-btn {
    display: none;
}
</style>